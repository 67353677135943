import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Label, Table, Spinner } from 'flowbite-react';
import { HTTPError } from 'ky';
import { Helmet } from 'react-helmet';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { HiHome } from 'react-icons/hi';
import { toast } from 'react-toastify';
import type { Analytic } from 'types/analytic';
import { type GsErrors } from 'types/gs_errors';
import { type RegisteredKeyEvent } from 'types/registered_key_event';
import { getKeyEvents } from 'domains/api/getKeyEvents';
import { getRegisteredKeyEvents } from 'domains/api/getRegisteredKeyEvents';
import { postRegisteredKeyEvent } from 'domains/api/postRegisteredKeyEvent';
import { usePageTracking } from 'utils/ga';
import FormInput from 'components/atoms/FormInput';
import EditKeyEventModal from 'components/molecules/Settings/KeyEvents/EditKeyEventModal';

type KeyEventData = {
  name: string;
  apiName: string;
};
const KeyEvents: FC<PropsWithChildren> = () => {
  usePageTracking(); // ページビュー計測

  const analytic = useLoaderData() as Analytic;
  const { analyticId } = useParams();
  const [keyEvents, setKeyEvents] = useState<string[] | undefined>(undefined);
  const [registeredKeyEvents, setRegisteredKeyEvents] = useState<
    RegisteredKeyEvent[] | undefined
  >(undefined);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editKeyEvent, setEditKeyEvent] = useState<
    undefined | RegisteredKeyEvent
  >(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    void (async () => {
      if (analyticId != null) {
        const keyEventResponse = await getKeyEvents(analyticId);
        setKeyEvents(keyEventResponse.keyEvents);
        const registeredKeyEventResponse =
          await getRegisteredKeyEvents(analyticId);
        setRegisteredKeyEvents(registeredKeyEventResponse.keyEvents);
      }
      setInitialLoading(false);
    })();
  }, [analyticId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<KeyEventData>({
    defaultValues: {
      name: '',
      apiName: '',
    },
  });

  const onSubmit: SubmitHandler<KeyEventData> = async (data: KeyEventData) => {
    try {
      setLoading(true);
      await postRegisteredKeyEvent(analytic.id, data);
      setLoading(false);
      navigate(0);
    } catch (error: unknown) {
      if (error instanceof HTTPError) {
        const response = (await error.response.json()) as GsErrors;
        response.errors.forEach((errorMsg: string) => toast.error(errorMsg));
      }
      setLoading(false);
    }
  };

  const showEditModal = (registeredKeyEvent: RegisteredKeyEvent) => () => {
    setEditKeyEvent(registeredKeyEvent);
    setOpenEditModal(true);
  };

  return (
    <>
      <Helmet>
        <title>キーイベント設定: Growth Support</title>
      </Helmet>
      {initialLoading ? (
        <div className="text-center mt-40">
          <Spinner color="success" aria-label="loading spinner" size="xl" />
        </div>
      ) : (
        <>
          <div className="border-b py-3 pl-2 flex justify-between">
            <Breadcrumb aria-label="Default breadcrumb example">
              <Breadcrumb.Item href={`/analytics/${analyticId}`} icon={HiHome}>
                {analytic.name}
              </Breadcrumb.Item>
              <Breadcrumb.Item>キーイベント</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="border-b pt-5 pb-2 pl-2 flex justify-between">
            <h1 className="text-xl leading-none tracking-tight text-gray-900 md:text-xl dark:text-white">
              キーイベント(コンバージョン)の設定
            </h1>
          </div>
          <div className="my-4 mx-3">
            <p className="text-gray-700">
              キーイベントを登録するとレポートに反映されます（上限3つ）
            </p>
            <div className="border-b py-3 pl-2">
              <h1 className="mt-4 mb-2 text-lg leading-none tracking-tight text-gray-900 md:text-lg dark:text-white">
                登録済みキーイベント一覧
              </h1>
              {registeredKeyEvents && registeredKeyEvents.length > 0 ? (
                <div className="mt-4 mb-2 block">
                  <Table>
                    <Table.Head>
                      <Table.HeadCell>レポート表示名</Table.HeadCell>
                      <Table.HeadCell>キーイベント</Table.HeadCell>
                      <Table.HeadCell>
                        <span className="sr-only">Edit</span>
                      </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                      {registeredKeyEvents.map((registeredKeyEvent) => (
                        <Table.Row
                          key={registeredKeyEvent.id}
                          className="bg-white dark:border-gray-700 dark:bg-gray-800"
                        >
                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            {registeredKeyEvent.name}
                          </Table.Cell>
                          <Table.Cell>{registeredKeyEvent.apiName}</Table.Cell>
                          <Table.Cell>
                            <Button
                              onClick={showEditModal(registeredKeyEvent)}
                              color="light"
                            >
                              <span className={'text-md'}>編集</span>
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  {openEditModal && editKeyEvent ? (
                    <EditKeyEventModal
                      openModal={openEditModal}
                      setOpenModal={setOpenEditModal}
                      keyEvent={editKeyEvent}
                      analyticId={analytic.id}
                    />
                  ) : null}
                </div>
              ) : (
                <div className="mt-4 mb-2 block text-gray-700">
                  <div>登録済みキーイベントはありません</div>
                </div>
              )}
            </div>
            <div className="border-b py-3 pl-2">
              <h1 className="mt-4 mb-2 text-lg leading-none tracking-tight text-gray-900 md:text-lg dark:text-white">
                未登録キーイベント一覧
              </h1>
              <div className="text-md leading-7 mb-4 text-gray-700">
                レポートに表示するキーイベントを登録してください。
                <br /> ※ 登録は
                <span className="text-red-500 text-md">最大3つまで</span>
                で、
                <span className="text-red-500 text-md">
                  登録後の変更はできません
                </span>
                のでご注意ください。
              </div>
              <div>
                {keyEvents &&
                keyEvents.length > 0 &&
                registeredKeyEvents &&
                registeredKeyEvents.length <= 2 ? (
                  <>
                    <form
                      className="max-w-xl pl-8"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <FormInput
                        label="レポート表示名"
                        attribute="name"
                        register={register('name', { required: true })}
                        errors={errors}
                        required={true}
                      />
                      <div className="mt-5 mb-8">
                        <div className="mt-4 mb-4 block">
                          <Label htmlFor="apiName">
                            登録するキーイベント
                            <span className="p-contact__titleSupplement text-md">
                              ( 必須 )
                            </span>
                          </Label>
                        </div>
                        <select
                          id="apiName"
                          {...register('apiName', { required: true })}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-md leading-6 rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="" disabled>
                            選択してください
                          </option>
                          {keyEvents.map((keyEvent) => {
                            return (
                              <option key={keyEvent} value={keyEvent}>
                                {keyEvent.replace('keyEvents:', '')}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <Button
                        type={'submit'}
                        color={'gray'}
                        isProcessing={loading}
                      >
                        登録する
                      </Button>
                    </form>
                  </>
                ) : (
                  <div className="mt-4 mb-2 block">
                    <div>
                      登録できるキーイベントがありません、または登録されたキーイベントが上限(3つ)に達しています。
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default KeyEvents;
